import { useAuth0 } from '@auth0/auth0-react';

import { APIClient } from '@repo/api-client';
import {
  DefaultError,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { useAnalytics } from '../analytics';
import { useAnalyticsSessionId } from '../analytics/store';

export const useSpxApiMutation = <
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const sessionId = useAnalyticsSessionId();
  const { trackError } = useAnalytics();
  const { mutationFn, ...restOfOptions } = options;

  return useMutation({
    mutationFn: mutationFn
      ? async (variables) => {
          APIClient.client.addSessionIdHeader(sessionId);

          // NOTE: this prevents a bug, when user is not authenticated, the method `getAccessTokenSilently` was waiting for a long long time
          // see discussion here: https://github.com/auth0/auth0-react/issues/291#issuecomment-954850027
          if (!isAuthenticated) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return mutationFn(variables);
          }

          try {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
              APIClient.client.addAccessTokenHeader(accessToken);
            }
          } catch (error) {
            trackError(error);
            console.error(error);
          }
          return mutationFn(variables);
        }
      : undefined,
    ...restOfOptions,
  });
};
