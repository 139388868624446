import { useAuth0 } from '@auth0/auth0-react';
import { APIClient } from '@repo/api-client';
import {
  DefaultError,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { useAnalytics } from '../analytics/Provider';
import { useAnalyticsSessionId } from '../analytics/store';

export const checkQueryIsEnabled = (options: {
  isAuthOptional?: boolean;
  isAuthenticated: boolean;
  skip?: boolean;
}): boolean => {
  const { isAuthOptional, isAuthenticated, skip } = options;

  if (skip) return false;

  if (isAuthOptional) return true;

  if (!isAuthOptional) return isAuthenticated;

  return false;
};

export const useSpxApiQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'enabled'
  > & {
    isAuthOptional?: boolean;
    skip?: boolean;
  },
): UseQueryResult<TData, TError> => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const sessionId = useAnalyticsSessionId();
  const { trackError } = useAnalytics();
  const { queryFn, isAuthOptional, skip, ...restOfOptions } = options;

  return useQuery({
    queryFn: async () => {
      APIClient.client.addSessionIdHeader(sessionId);

      // NOTE: this prevents a bug, when user is not authenticated, the method `getAccessTokenSilently` was waiting for a long long time
      // see discussion here: https://github.com/auth0/auth0-react/issues/291#issuecomment-954850027
      if (!isAuthenticated) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return queryFn();
      }

      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          APIClient.client.addAccessTokenHeader(accessToken);
        }
      } catch (error) {
        trackError(error);
        console.error(error);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queryFn();
    },
    enabled: () =>
      checkQueryIsEnabled({
        isAuthOptional,
        isAuthenticated,
        skip,
      }),
    ...restOfOptions,
  });
};
